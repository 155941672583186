<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="搜索条件">
        <el-input
          size="mini"
          v-model="formInline.user"
          placeholder="ID/昵称"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select size="mini" v-model="formInline.region" placeholder="请选择">
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="正常" :value="1"></el-option>
          <el-option label="禁用" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
        <el-button
          size="mini"
          type="success"
          icon="el-icon-edit"
          @click="allopen"
          >批量审核</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'left'
      }"
      :cell-style="{ textAlign: 'left' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" :selectable="checkboxT">
      </el-table-column>
      <el-table-column prop="userNo" label="ID"> </el-table-column>
      <el-table-column prop="date" label="昵称 I 手机">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.avatar"
            :preview-src-list="[row.avatar]"
          >
          </el-image>
          <div>
            <div>昵称：{{ row.nickname }}</div>
            <div>手机：{{ row.phone }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="用户资料">
        <template slot-scope="{ row }">
          <div>
            <div>用户进度：{{ row.userProgress }}</div>
            <div>用户等级：{{ row.userLevel }}</div>
            <div>用户备注：{{ row.userRemark }}</div>
            <div>用户生日：{{ row.birthday }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="所属门店">
        <template>{{ name }}</template>
      </el-table-column>
      <el-table-column prop="address" label="答题统计">
        <template slot-scope="{ row }">
          <div>
            <div>奖励金额：{{ row.rewardAmount }}</div>
            <div>用户学分：{{ row.userScore }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="加入时间"> </el-table-column>
      <el-table-column prop="address" label="状态">
        <template slot-scope="{ row }">
          {{ state[row.userStatus] }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            v-if="row.userStatus !== 0"
            @click="edit(row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            v-if="row.userStatus == 0"
            @click="open(row)"
            >审核通过</el-button
          >
          <el-button type="primary" size="mini" @click="goDetails(row)"
            >学习答题</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="500px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="ruleForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="用户等级">
          <el-input v-model="ruleForm.userLevel"></el-input>
        </el-form-item>
        <el-form-item label="用户进度">
          <el-input v-model="ruleForm.userProgress"></el-input>
        </el-form-item>
        <el-form-item label="用户备注">
          <el-input v-model="ruleForm.userRemark"></el-input>
        </el-form-item>
        <el-form-item label="用户生日">
          <el-date-picker
            v-model="ruleForm.birthday"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用户状态" prop="userStatus">
          <el-radio-group v-model="ruleForm.userStatus">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import {
  getUserPage,
  updateUser,
  reviewUser,
  file,
  batchReviewUser
} from '../../../api/user'
export default {
  data() {
    return {
      ruleForm: {
        id: '',
        phone: '',
        avatar: '',
        nickname: '',
        userStatus: '',
        userLevel: '',
        userProgress: '',
        userRemark: '',
        birthday: ''
      },
      rules: {
        userStatus: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dialogVisible: false,
      state: ['待审核', '正常', '禁用'],
      name: localStorage.getItem('nickname'),
      tableData: [],
      total: 0,
      size: 20,
      current: 1,
      formInline: {
        user: '',
        region: ''
      },
      idList: []
    }
  },
  created() {
    this.getUserPage()
  },
  methods: {
    checkboxT(row, index) {
      return row.userStatus == 0
    },
    handleSelectionChange(val) {
      this.idList = val.map((res) => {
        return res.id
      })
      console.log(this.idList)
    },
    goDetails(row) {
      this.$router.push(`/userList/answerRecord?id=${row.id}`)
    },
    search() {
      this.current = 1
      this.getUserPage()
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = await updateUser(this.ruleForm)
          this.dialogVisible = false
          this.getUserPage()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async beforeAvatarUpload(file1) {
      const { data } = await file({
        fileName: file1.name
      })
      data.body.file = file1
      const formData = new FormData()
      for (const key in data.body) {
        formData.append(key, data.body[key])
      }
      axios({
        method: 'post',
        url: data.post,
        data: formData
      }).then((res) => {
        this.ruleForm.avatar = data.url
      })
      return false
    },
    edit(row) {
      this.ruleForm = {
        id: row.id,
        phone: row.phone,
        avatar: row.avatar,
        nickname: row.nickname,
        userStatus: row.userStatus,
        userLevel: row.userLevel,
        userProgress: row.userProgress,
        userRemark: row.userRemark,
        birthday: row.birthday
      }
      this.dialogVisible = true
    },
    allopen() {
      if (!this.idList.length) {
        return this.$message({
          type: 'warning',
          message: '请选择用户!'
        })
      }
      this.$confirm('是否确定批量审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = await batchReviewUser({
            idList: this.idList,
            userStatus: 1
          })
          console.log(data)
          this.getUserPage()
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.idList = []
        })
        .catch(() => {})
    },
    open(row) {
      this.$confirm('是否确定审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = await reviewUser({
            id: row.id,
            userStatus: 1
          })
          console.log(data)
          this.getUserPage()
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {})
    },
    async getUserPage() {
      const { data } = await getUserPage({
        pageSize: this.size,
        pageNum: this.current,
        searchKey: this.formInline.user,
        userStatus: this.formInline.region
      })
      console.log(data)
      this.tableData = data.list
      this.total = data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      this.getUserPage()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getUserPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
